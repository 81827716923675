import React from 'react';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Receivers from './mshippers/AppReceivers';
import TagManager from 'react-gtm-module';
import ls from 'local-storage';
import ReactDOM from 'react-dom';
import { registerPushNotificationServiceWorker } from './serviceWorker';

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    dataLayer: {
        event: 'page-change',
        userId: ls.get('USER_PAYLOAD') ? ls.get('USER_PAYLOAD').id : '',
        userType: ls.get('USER_PAYLOAD') ? ls.get('USER_PAYLOAD').user_type : ''
    }
}
TagManager.initialize(tagManagerArgs);
ReactDOM.render(<Receivers/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// if ('serviceWorker' in navigator) {
//     caches.keys().then(function(cacheNames) {
//         cacheNames.forEach(function(cacheName) {
//             caches.delete(cacheName);
//         });
//     });
// }
// serviceWorker.register();

registerPushNotificationServiceWorker();